<template>
  <div>
    <div class="mb-4">
      <b-button class="mr-2" @click="loadCampaignTickets(campaignId)"
        >Refresh</b-button
      >
      <b-button v-b-modal.modal-ticket>Add Tickets</b-button>
    </div>

    <b-modal ref="modal-ticket" id="modal-ticket" hide-footer>
      <FormulateForm :values="ticketValues" @submit="submitTicket">
        <FormulateInput
          type="textarea"
          name="message"
          validation="required"
          label="Message"
        />
        <FormulateInput
          type="file"
          name="attachment"
          label="Select an image to upload"
          help="Select a png, jpg or gif to upload."
          validation="mime:image/jpeg,image/png,image/gif"
          multiple
        />
        <div class="d-flex justify-content-end">
          <FormulateInput
            type="submit"
            :label="addingTicket ? 'Adding..' : 'Add'"
          />
        </div>
      </FormulateForm>
    </b-modal>
    <div v-if="loading" class="text-center">Loading...</div>
    <div v-else>
      <div v-if="tickets.length === 0" class="text-center">No Tickets</div>
      <div v-else>
        <div class="card p-3" v-for="ticket in tickets" :key="ticket._id">
          {{ ticket.message }}

          <div
            v-if="ticket.attachment && ticket.attachment.length"
            style="
              background-color: #fafafa;
              padding: 4px;
              border-radius: 8px;
              margin-top: 8px;
            "
          >
            <strong>Attachments</strong> <br />

            <span v-for="attachment in ticket.attachment" :key="attachment">
              <a :href="attachment" target="_blank">
                <img
                  style="width: 68px; margin: 4px"
                  :src="attachment"
                  alt="Attachment"
                />
              </a>
            </span>
          </div>
          <div>
            <div style="padding: 4px">
              Created : {{ moment(ticket.createdAt).format("LLL") }}
            </div>
            <div>
              <div class="user-details-ticket" v-if="ticket.user">
                {{ ticket.user.email }}<br />
                +{{ ticket.user.country_code }}- {{ ticket.user.phone_number }}
              </div>
              <div v-else class="user-details-ticket">BSEEN- Admin</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { campaigns } from "@/config/api/campaigns";
export default {
  props: ["campaignId", "loadCampaigns"],
  data() {
    return {
      tickets: [],
      loading: false,
      ticketValues: {},
      addingTicket: false,
    };
  },

  methods: {
    loadCampaignTickets(id) {
      const api = campaigns.getCampaignTickets;
      api.id = id;
      this.loading = true;
      this.generateAPI(api)
        .then((res) => {
          this.tickets = res.data.tickets;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    submitTicket(data) {
      const api = campaigns.createCampaignTicket;
      if (data.attachment) {
        data.attachment = data.attachment.map((item) => item.location);
      }
      data.campaignId = this.campaignId;
      api.data = data;
      this.addingTicket = true;
      this.generateAPI(api)
        .then(() => {
          this.$bvModal.hide("modal-ticket");
          this.loadCampaignTickets(this.campaignId);
        })
        .catch((err) => {
          console.log(err.response.data);
        })
        .finally(() => {
          this.addingTicket = false;
        });
    },
  },
  watch: {
    loadCampaigns(val) {
      if (val && this.campaignId) {
        this.loadCampaignTickets(this.campaignId);
      }
    },
  },
};
</script>

<style scoped>
.user-details-ticket {
  text-align: end;
  margin-top: 12px;
  font-weight: 600;
}
</style>
