<template>
  <b-modal
    id="reject-design"
    @hidden="$emit('closeModal')"
    title="Reject design"
    hide-footer
  >
    <FormulateForm
      name="RejectDesign"
      v-model="values"
      :schema="schema"
      @submit="submitHandler"
    >
      <FormulateInput type="submit" class="float-right">
        <span v-if="performAction">
          <b-spinner class="vueformulate-loader"></b-spinner>
          <span> Loading... </span>
        </span>
        <span v-else>
          <span> Reject </span>
        </span>
      </FormulateInput>
    </FormulateForm>
  </b-modal>
</template>

<script>
import { campaigns } from "@/config/api/campaigns";
export default {
  props: ["campaignId"],
  data() {
    return {
      values: {},
      performAction: false,
      schema: [
        {
          index: 0,
          type: "text",
          name: "reason",
          label: "Reason",
          validation: "required",
        },
      ],
    };
  },
  methods: {
    async submitHandler(data) {
      this.performAction = true;
      data.campaign_id = this.campaignId;
      const api = campaigns.rejectDesignV2;
      api.data = data;
      this.generateAPI(api)
        .then((res) => {
          this.performAction = false;
          this.$bvToast.toast("Design rejected!", {
            title: "Design reject",
            variant: "success",
            solid: true,
          });
          this.$emit("reloadData");
          this.$emit("closeModal");
          this.$store.dispatch("campaign/selectCampaign", res.data.campaign);
        })
        .catch((err) => {
          this.performAction = false;
          console.log(err.data);
        });
    },
  },
};
</script>
