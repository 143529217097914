<template>
    <div>
        <b-button @click="openModal" variant="primary" size="sm" class="p-0 ml-2"><i class="ri-pencil-line"></i> change
        </b-button>

        <b-modal id="change-company-modal" title="Change company" hide-footer>
            <FormulateForm @submit="submitForm" v-model="formValues">
                <!-- <FormulateInput name="no_of_drivers_required" type="number" label="No. of drivers required"
                    placeholder="No. of drivers required" validation="required" /> -->
                    <b-form-group id="input-group-1" label="Company:" label-for="input-2">
                        <model-select class="mt-2" v-model="selectedCompany" :options="companies" :selectedOption="data.selected_company" placeholder="select item" option-value="_id"
                     option-text="name">
                        </model-select>
                    </b-form-group>
                <div class="text-right">
                    <FormulateInput type="submit" :label="changing ? 'Changing...' : 'Change'" />
                </div>
            </FormulateForm>
        </b-modal>
    </div>
</template>

<script>
import { company } from "@/config/api/company";
import { ModelSelect } from "vue-search-select";
export default {
    components: {
        ModelSelect,
    },
    props: ["data"],
    data() {
        return {
            formValues: {},
            companies:[],
            changing: false,
            selectedCompany:null
        };
    },
    created() {
        this.getCompanies();
    },
    methods: {
        getCompanies() {
            const api = company.getAll;
            
            this.generateAPI(api)
                .then((res) => {
                    this.companies = res.data.companies;
                    this.companies = this.companies.map((comp) => ({
                        ...comp,
                        text: comp.name,value:comp._id 
                    }));
                })
                .catch((err) => {
                    console.error(err);
                })
                .finally(() => {
                    this.isBusy = false;
                });
        },
        submitForm() {
            const api = company.changeCompany;
            api.id = this.data.id;
            api.data = {
                companyId: this.selectedCompany
            };
            this.changing = true;
            this.generateAPI(api)
                .then((res) => {
                    this.$bvModal.hide("change-company-modal");
                    this.$emit("change", res.data.company);
                    this.$bvToast.toast("Changed company", {
                        title: "Done",
                        variant: "success",
                        solid: true,
                    });
                })
                .catch((err) => {
                    this.$bvToast.toast(
                        `Required Driver is not changed!, ${err.response.data.error}`,
                        {
                            title: "Required Driver",
                            variant: "danger",
                            solid: true,
                        }
                    );
                })
                .finally(() => {
                    this.changing = false;
                });
        },
        openModal() {
            this.formValues.no_of_drivers_required = this.data.no_of_drivers_required;
            this.$bvModal.show("change-company-modal");
        },
    },
};
</script>

<style>

</style>
