<template>
  <div>
    <b-button @click="openModal" variant="primary" size="sm" class="p-0 ml-2"
      ><i class="ri-pencil-line"></i> change</b-button
    >

    <b-modal id="driver-required-modal" title="No. of Drivers" hide-footer>
      <FormulateForm @submit="submitForm" v-model="formValues">
        <FormulateInput
          name="no_of_drivers_required"
          type="number"
          label="No. of drivers required"
          placeholder="No. of drivers required"
          validation="required"
        />
        <div class="text-right">
          <FormulateInput
            type="submit"
            :label="changing ? 'Changing...' : 'Change'"
          />
        </div>
      </FormulateForm>
    </b-modal>
  </div>
</template>

<script>
import { campaigns } from "@/config/api/campaigns";
export default {
  props: ["data"],
  data() {
    return {
      formValues: {},
      changing: false,
    };
  },
  methods: {
    submitForm() {
      const api = campaigns.changeNoOfDrivers;
      api.data = {
        campaignId: this.data.id,
        no_of_drivers_required: this.formValues.no_of_drivers_required,
      };
      this.changing = true;
      this.generateAPI(api)
        .then((res) => {
          this.$bvModal.hide("driver-required-modal");
          this.$emit("change", res.data.campaign.no_of_drivers_required);
          this.$bvToast.toast("Required Driver!", {
            title: "Required Driver",
            variant: "success",
            solid: true,
          });
        })
        .catch((err) => {
          this.$bvToast.toast(
            `Required Driver is not changed!, ${err.response.data.error}`,
            {
              title: "Required Driver",
              variant: "danger",
              solid: true,
            }
          );
        })
        .finally(() => {
          this.changing = false;
        });
    },
    openModal() {
      this.formValues.no_of_drivers_required = this.data.no_of_drivers_required;
      this.$bvModal.show("driver-required-modal");
    },
  },
};
</script>

<style></style>
