<template>
  <div class="add-driver">
    <b-button @click="openModal" variant="primary"> Finish Wrapping </b-button>

    <b-modal
      :id="`finish-wrapping-modal${uuid}`"
      title="Finish Wrapping"
      hide-footer
    >
      <strong> Are you sure, Do you want to finish the Job?</strong>

      <b-button class="my-1 float-right" @click="finishWorkstation">
        <span v-if="submitting"> Submitting...</span>
        <span v-else> Yes </span>
      </b-button>
    </b-modal>
  </div>
</template>

<script>
import uuid from "@/utils/idGen";
import { campaigns } from "@/config/api/campaigns";
export default {
  props: ["campaignId"],
  components: {},
  data() {
    return {
      submitting: false,
    };
  },

  computed: {
    uuid() {
      return uuid();
    },
  },

  methods: {
    finishWorkstation() {
      const api = campaigns.completeJob;
      api.data = {
        ad_id: this.campaignId,
      };
      this.submitting = true;
      this.generateAPI(api)
        .then(() => {
          this.$bvToast.toast("Job finished successfully", {
            title: "Wrapping Job",
            variant: "success",
            solid: true,
          });
          this.$bvModal.hide(`finish-wrapping-modal${this.uuid}`);
          this.$emit("reload");
        })
        .catch((err) => {
          this.$bvToast.toast(
            `Job is not finished!, ${err.response.data.error}!`,
            {
              title: "Wrapping Job",
              variant: "danger",
              solid: true,
            }
          );
        })
        .finally(() => {
          this.submitting = false;
        });
    },

    async openModal() {
      this.$bvModal.show(`finish-wrapping-modal${this.uuid}`);
    },
  },
};
</script>

<style></style>
