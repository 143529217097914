<template>
  <div>
    <b-button @click="openModal" variant="primary" size="sm" class="p-0 ml-2"
      ><i class="ri-pencil-line"></i> Change</b-button
    >

    <b-modal id="day-change-modal" title="Change days" hide-footer>
      <FormulateForm
        class="flat-rate-form"
        @submit="changeFlatValue"
        v-model="formValues"
      >
        <FormulateInput
          name="no_of_days"
          type="number"
          label="Number of days"
          validation="required"
        />
        <div class="text-right">
          <FormulateInput
            type="submit"
            :label="changing ? 'Changing...' : 'Change'"
          />
        </div>
      </FormulateForm>
    </b-modal>
  </div>
</template>

<script>
import { campaigns } from "@/config/api/campaigns";
export default {
  props: ["data"],
  data() {
    return {
      formValues: {},
      changing: false,
    };
  },
  methods: {
    changeFlatValue() {
      const api = campaigns.changeNoOfDays;
      api.id = this.data._id;
      api.data = {
        no_of_days: this.formValues.no_of_days,
      };
      this.changing = true;
      this.generateAPI(api)
        .then((res) => {
          this.$emit("change", res.data.campaign.no_of_days);
          this.$bvToast.toast("No. days are changed!", {
            title: "No. Days",
            variant: "success",
            solid: true,
          });
        })
        .catch((err) => {
          this.$bvToast.toast(
            `No. days are not changed!, ${err.response.data.error}`,
            {
              title: "No. Days",
              variant: "danger",
              solid: true,
            }
          );
        })
        .finally(() => {
          this.changing = false;
        });
    },
    openModal() {
      this.formValues.no_of_days = this.data.no_of_days;
      this.$bvModal.show("day-change-modal");
    },
  },
};
</script>

<style></style>
