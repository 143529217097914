<template>
  <span>
    <button @click="openModal" class="bonus-btn ml-2">
      <i class="ri-pencil-line"></i> Change
    </button>

    <b-modal id="start_time-modal" title="Change start date" hide-footer>
      <FormulateForm
        class="start_date_form"
        v-model="formValues"
        @submit="updateStartDate"
      >
        <FormulateInput
          type="date"
          name="start_within"
          label="Starting within date"
        />
        <div class="d-flex justify-content-end">
          <FormulateInput
            type="submit"
            :label="updating ? 'Updating...' : 'Update'"
          />
        </div>
      </FormulateForm>
    </b-modal>
  </span>
</template>

<script>
import { campaigns } from "@/config/api/campaigns";

export default {
  props: ["data"],
  data() {
    return {
      formValues: {},
      updating: false,
    };
  },
  methods: {
    updateStartDate() {
      this.updating = true;
      const api = campaigns.changeStartDate;
      api.data = {
        campaignId: this.data.id,
        start_within: this.formValues.start_within,
      };
      this.generateAPI(api)
        .then(() => {
          this.$emit("change");
          this.$bvToast.toast("Starting within date updated!", {
            title: "Change",
            variant: "success",
            solid: true,
          });
        })
        .catch((err) => {
          this.$bvToast.toast(
            `Starting within date is not updated!,${err.response.data.error}`,
            {
              title: "Change",
              variant: "danger",
              solid: true,
            }
          );
        })
        .finally(() => {
          this.updating = false;
        });
    },
    openModal() {
      this.$bvModal.show("start_time-modal");
      this.formValues.start_within = this.moment(this.data.start_within).format(
        "YYYY-DD-MM"
      );
    },
  },
};
</script>

<style>
</style>