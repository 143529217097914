<template>
  <div>
    <b-button v-b-modal.make-payment variant="primary">Make payment</b-button>

    <b-modal id="make-payment" title="Make payment" hide-footer>
      <FormulateForm
        class="payment-form"
        v-model="formValues"
        @submit="submitForm"
      >
        <FormulateInput
          name="remark"
          type="textarea"
          label="Remark"
          placeholder="Remark here"
        />
        <FormulateInput
          name="file"
          type="file-input"
          label="Evidence"
          :value="[]"
          placeholder="Evidence here"
        />

        <div class="text-center">
          <FormulateInput
            type="submit"
            :label="paying ? 'Paying...' : 'Make Payment'"
          />
        </div>
      </FormulateForm>
    </b-modal>
  </div>
</template>

<script>
import { payment } from "@/config/api/payment";
import { getFormData } from "@/utils/createForm";
export default {
  props: ["id"],
  data() {
    return {
      formValues: {},
      paying: false,
    };
  },
  methods: {
    submitForm() {
      const data = {
        campaignId: this.id,
        remark: this.formValues.remark,
        file: this.formValues.file,
      };
      this.paying = true;
      const api = payment.addPaymentAdmin;
      api.data = getFormData(data);
      this.generateAPI(api)
        .then(() => {
          this.$bvToast.toast("Payment successfull!", {
            title: "Payment",
            variant: "success",
            solid: true,
          });
          this.$bvModal.hide("make-payment");
          this.$emit("reload")
         
        })
        .catch((err) => {
          this.$bvToast.toast(
            `Payment is not successfull!, ${err.response.data.error}`,
            {
              title: "Payment",
              variant: "danger",
              solid: true,
            }
          );
        })
        .finally(() => {
          this.paying = false;
        });
    },
  },
};
</script>

<style></style>
