<template>
  <Layout>
    <div v-if="loadingDetails">
      <b-spinner type="grow" label="Spinning"></b-spinner>Loading...
    </div>

    <div v-if="!loadingDetails && campaign">
      <b-tabs @input="updateTabs" content-class="mt-3" v-model="activeTab">
        <!-- ========Main tab============ -->
        <b-tab title="Overview" active>
          <div class="d-flex justify-content-end">
            <b-dropdown right variant="primary" class="m-2">
              <template #button-content>
                <i class="ri-settings-2-line"></i>
              </template>
              <b-dropdown-item variant="danger" @click="deleteCampaign(campaign._id)">Delete</b-dropdown-item>
              <b-dropdown-item variant="danger" @click="finishCampaign(campaign._id)">Finish</b-dropdown-item>
              <b-dropdown-item variant="success" @click="viewAnalytics(campaign._id)">Analytics</b-dropdown-item>
              <b-dropdown-item variant="danger" @click="makeRecurring(campaign._id)">Make It <span
                  v-if="!campaign.recurring"> Recurring</span>
                <span v-else> Non Recurring </span>
              </b-dropdown-item>
            </b-dropdown>
          </div>
          <div class="campaign-details">
            <div class="expired-block" v-if="expired(campaign)">
              <span> Expired</span>
            </div>
            <div class="expired-block" v-if="campaign.status === 5">
              <span> Completely Allocated </span>
            </div>
            <div class="status-title" v-if="campaign.status === 0" style="color: red">
              <span>
                {{
                  campaign.payment_done
                    ? "Pending for verification"
                    : "Pending Payment"      
                }}
              </span>
            </div>
            <div class="status-title" v-else-if="campaign.status === 1" style="color: green">
              <span> Campaign Verified </span>
            </div>
            <div v-if="campaign.status === 0" class="status-title">
              <div v-if="campaign.payment_done">
                <button class="primary-btn btn verify-btn" style="color: green" @click="verifyCampaign">
                  <span v-if="updateStatus">Verifying Campaign...</span><span v-else>Verify Campaign</span>
                </button>
              </div>
            </div>
            <div v-if="campaign.status === 2" class="status-title">
              <button class="primary-btn btn verify-btn" style="color: green" v-if="campaign.status === 2"
                @click="verifyDesign">
                <span v-if="updateStatus"> Verifying Design... </span><span v-else>Verify Design</span>
              </button>

              <button class="primary-btn btn verify-btn" style="color: red; margin-left: 5px"
                v-if="campaign.status === 2" @click="showActionModal">
                <span>Reject Design</span>
              </button>
            </div>
            <div v-if="campaign">
              <PaymentModal v-on:loadCampaign="loadAndHide" v-if="!campaign.payment_done" :id="campaign._id"
                v-on:reload="getCampaign" />
            </div>

            <div class="card" v-if="campaign.createdAt">
              <div class="card-body">
                <div class="row">
                  <div class="col-4">Recurring</div>
                  <div class="col-8">
                    <strong> {{ campaign.recurring }}</strong>
                  </div>
                </div>
                <div class="row">
                  <div class="col-4">Sticker Type</div>
                  <div class="col-8">
                    {{ campaign.sticker_type }}
                  </div>
                </div>
                <div class="row">
                  <div class="col-4">Bonus</div>
                  <div class="col-8">
                    {{ campaign.bonus_amount || 0 }} SAR
                    <button v-b-modal.bonus-modal class="bonus-btn ml-2">
                      <i class="ri-pencil-line"></i> Bonus
                    </button>
                  </div>
                </div>
                <div class="row">
                  <div class="col-4">Price</div>
                  <div class="col-8">{{ campaign.plan_price }} SAR
                    <b-button class="row sm-4" variant="primary" style="height: 30px; margin-left:10px" size="sm"
                      @click="openupdatePriceModal(campaign)">
                      <i class="ri-pencil-line"></i> <span>Update Price</span>
                    </b-button>
                  </div>
                </div>
                <div class="row">
                  <div class="col-4">Flat rate</div>
                  <div class="col-8 d-flex">
                    {{ campaign.flat_rate }} SAR
                    <flat-rate-modal :data="{
                      id: campaign._id,
                      flat_rate: campaign.flat_rate,
                    }" v-on:change="changeFlatRate" />
                  </div>
                </div>

                <div class="row mt-1">
                  <div class="col-4">No. of Drivers required</div>
                  <div class="col-8 d-flex">
                    {{ campaign.no_of_drivers_required }}
                    <change-no-drivers :data="{
                      id: campaign._id,
                      no_of_drivers_required: campaign.no_of_drivers_required,
                    }" v-on:change="changeDriverRequired" />
                  </div>
                </div>
                <div class="row">
                  <div class="col-4">No. of Drivers assigned</div>
                  <div class="col-8">
                    {{ campaign.no_of_drivers_assigned }}
                  </div>
                </div>
                <div class="row">
                  <div class="col-4">Amount Used</div>
                  <div class="col-8">
                    {{ campaign.amount_used.toFixed(2) }}
                  </div>
                </div>
                <div class="row">
                  <div class="col-4">Payment Done</div>
                  <div class="col-8">
                    <strong>{{ campaign.payment_done ? "Yes" : "No" }}</strong>
                  </div>
                </div>
                <div class="row">
                  <div class="col-4">Payment Type</div>
                  <div class="col-8">
                    {{ campaign.payment_type }}
                  </div>
                </div>
                <div class="row">
                  <div class="col-4">Start within</div>
                  <div class="col-8">
                    {{ moment(campaign.start_within).format("LL") }}
                    <start-time-modal @change="getCampaign()" :data="{
                      id: campaign._id,
                      start_within: campaign.start_within,
                    }" />
                  </div>
                </div>
                <div class="row">
                  <div class="col-4">No. of Days</div>
                  <div class="col-8 d-flex">
                    {{ campaign.no_of_days }}
                    <change-days :data="campaign" @change="dayChange" />
                  </div>
                </div>

                <div class="row">
                  <div class="col-4">Campaign Created</div>
                  <div class="col-8">
                    {{ moment(campaign.createdAt).format("LLL") }}
                  </div>
                </div>
                <div class="row">
                  <div class="col-4">Campaign Last Updated</div>
                  <div class="col-8">
                    {{ moment(campaign.updatedAt).format("LLL") }}
                  </div>
                </div>
                <div class="row">
                  <div class="col-4">Show Vehicle Info</div>
                  <div class="col-8">
                    <!-- {{campaign.show_vehicle_info}} -->
                    <toggle-button v-model="showDriverInfo" color="#000000" @change="changeShowDriverDetailStatus"
                      :labels="{ checked: 'Yes', unchecked: 'No' }" />
                  </div>
                </div>

                <div class="row">
                  <div class="col-4">Premium</div>
                  <div class="col-8">
                    <!-- {{campaign.show_vehicle_info}} -->
                    <toggle-button v-model="isPremium" color="#000000" @change="changePremiumStatus"
                      :labels="{ checked: 'Yes', unchecked: 'No' }" />
                  </div>
                </div>


                <div class="row">
                  <div class="col-4">Vehicle Tracking</div>
                  <div class="col-8" role="button" @click="goToTrackView">
                    Live Map
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-8 col-sm-12">
                <div class="card" v-if="campaign.plan">
                  <div class="title">Plan</div>
                  <div class="card-body plan-details">
                    <div class="row">
                      <div class="col-4">Name</div>
                      <div class="col-8">
                        {{ campaign.plan.name }}
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-4">Sticker Type</div>
                      <div class="col-8">
                        {{ campaign.plan.sticker_type }}
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-4">No. of Vehicles</div>
                      <div class="col-8">
                        {{ campaign.plan.no_of_vehicles }}
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-4">No. of Days</div>
                      <div class="col-8">
                        {{ campaign.plan.no_of_days }}
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-4">Price</div>
                      <div class="col-8">
                        {{ campaign.plan.price }}
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-4">Advertisment Duration</div>
                      <div class="col-8">
                        {{ campaign.plan.payment_duration }}
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-4">Features</div>
                      <div class="col-8">
                        <b-badge variant="light" class="m-2" v-for="feature in campaign.plan.features" :key="feature">
                          {{ feature }}
                        </b-badge>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-4">Not Included</div>
                      <div class="col-8">
                        <b-badge variant="light" class="m-2" v-for="not_included in campaign.plan.not_included"
                          :key="not_included">
                          {{ not_included }}
                        </b-badge>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-4">Status</div>
                      <div class="col-8">
                        <span v-if="campaign.plan.status">Active</span>
                        <span v-else>Inactive</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-4 col-sm-12">
                <div class="card" style="min-height: 296px" v-if="campaign.company">
                  <div class="title">Company</div>

                  <div class="card-body" style="padding-top: 4px; text-align: center">
                    <b-avatar :src="campaign.company.logo" rounded size="8rem"></b-avatar>
                    <div style="font-weight: 800">
                      <router-link :to="{
                        name: 'AdvertiserDetails',
                        params: { id: campaign.company.user_id._id },
                      }">
                        <h6>{{ campaign.company.name }}</h6>
                      </router-link>
                    </div>
                    Trade License No.:
                    {{ campaign.company.trade_license_number }}
                    <br />
                    <a :href="campaign.company.trade_license_doc" class="trade-licence" target="_blank">
                      Trade License</a>
                    <br />
                    <change-company :data="{
                      id: campaign._id,
                      selected_company: campaign.company._id,
                    }" v-on:change="changeCompany" />
                    <!-- v-on:change="changeDriverRequired" -->
                    <!-- <a href="#" class="trade-licence">
                      Change company</a> -->
                  </div>
                </div>
              </div>
              <div class="col-12" v-if="campaign.status >= 2">
                <div class="card">
                  <div class="title">Uploaded Files ({{ campaign.campaign_stickers.length }})</div>
                  <div class="card-body">
                    <div class="row">

                      <div v-for="(item, index) in campaign.campaign_stickers" :key="index"
                        style="background-color: #fafafa;padding: 12px; " class="col-3 ">
                        <div class="text-center">
                          Number of ads {{ item.no_of_ads }}
                        </div>

                        <div class="download-file m-2">
                          <img width="100px" :src="item.sample_file" alt="Sample file" />

                          <a class="download-btn" target="_blank" :href="item.sample_file">
                            Sample File</a>
                        </div>
                        <div class="download-file m-2">
                          <i class="fa fa-file" style="font-size: 24px"></i>
                          <br />
                          <a class="download-btn" target="_blank" :href="campaign.sticker_file">
                            Sticker File</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </b-tab>
        <!-- ====X===Main tab=======X==== -->

        <!-- =========Campaign images=========== -->
  <b-tab title="Campaign images" v-if="campaign.status > 3">
           

            
            <div class="row">
              <div class="col-12 my-4">
                <FormulateInput type="file" v-model="campaignImageFile" ref="camp_img" name="file" style="max-width: 480px; margin: auto"
                  :uploader="uploadCampaignImage" label="Please upload the image"  />
              </div>
              
              <!-- <div class="col-12 my-2 text-center">
                <b-button variant="primary" @click="addNewDesigns">
                  <span v-if="adding_new_design">
                    Adding....
                  </span>
                  <span v-else>
                    Add Design

                  </span>
                </b-button>
              </div> -->

              <div class="mt-4 p-3" style="border: 1px dashed black; width: 100%">
                <h6>Uploaded Images</h6>
                <div v-if="campaign.images.length">
                  <div v-for="(file,index) in campaign.images" :key="file">
                    <div class="card p-2">
                      <div class="row">
                        <div class="col-5 text-center">
                          <a :href="file" target="_blank">
                            
                            <!-- <strong>Sample File</strong> <br /> -->
                            <img :src="file" width="120" alt="Sample File" />
                          </a>
                        </div>
                        
                        <div class="col-2 text-center d-flex align-items-center">
                          <b-button variant="danger" size="sm" @click="deleteCampaignImage(index)">
                            Delete
                          </b-button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-else class="text-center">
                  <h6>Please Add file first!</h6>
                </div>
              </div>
              
            </div>
          </b-tab>
        <!-- =====x========Campaign images======x=== -->
        <!-- ===========Upoad design=========== -->
        <b-tab title="Upload Design" v-if="campaign.status === 3 || campaign.status === 1 || campaign.status === -3">
          <div v-if="campaign.advertiser_design_files.length" class="mb-3">
            Designs uploaded by the advertiser <br />
            {{ campaign.advertiser_design_files.length }}
          </div>
          <div v-if="campaign.design_support_images.length" class="mb-3">
            Support Image <br />
            {{ campaign.design_support_images }}
          </div>

          <div v-if="campaign.advertiser_design_message" class="mb-3">
            <strong> Design Message</strong>
            <div style="background-color: white; padding: 4px; border-radius: 4px">
              {{ campaign.advertiser_design_message }}
            </div>
          </div>
          <div class="row">
            <div class="col-12 my-4">
              <FormulateInput type="file" name="file" style="max-width: 480px; margin: auto"
                :uploader="uploadSampleFile" label="Please upload the sample" @file-removed="removeFile('sample')" />
            </div>
            <div class="col-12 my-4">
              <FormulateInput type="file" name="file" style="max-width: 480px; margin: auto"
                :uploader="uploadDesignFile" label="Upload the design file here" @file-removed="removeFile('design')"
                help="Please zip files and then upload" />
            </div>
            <div class="col-12 my-2 text-center">
              <b-button variant="primary" @click="addNewDesigns">
                <span v-if="adding_new_design">
                  Adding....
                </span>
                <span v-else>
                  Add Design

                </span>
              </b-button>
            </div>

            <div class="mt-4 p-3" style="border: 1px dashed black; width: 100%">
              <h6>Uploaded Files</h6>
              <div v-if="designList.length">
                <div v-for="file in designList" :key="file.id">
                  <div class="card p-2">
                    <div class="row">
                      <div class="col-5 text-center">
                        <a :href="file.sample_file" target="_blank">
                          <strong>Sample File</strong> <br />
                          <img :src="file.sample_file" width="120" alt="Sample File" />
                        </a>
                      </div>
                      <div class="col-5 text-center d-flex align-items-center">
                        <a class="btn btn-primary" target="_blank" :href="file.sticker_file">View Design File</a>
                      </div>
                      <div class="col-2 text-center d-flex align-items-center">
                        <b-button variant="danger" size="sm" @click="deleteItem(file.sample_file)">
                          Delete
                        </b-button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-else class="text-center">
                <h6>Please Add file first!</h6>
              </div>
            </div>
            <div class="col-12 text-center my-2">
              <b-button @click="designCompleted" v-if="campaign.status !== 3">
                <span v-if="submitingForApproval">Submiting...</span>
                <span v-else>Submit for Approval</span>
              </b-button>
            </div>
          </div>
        </b-tab>
        <!-- ====X======Upoad design=====X===== -->
        <!-- ========Rejected designs========== -->
        <b-tab title="Rejected designs" v-if="campaign.status === -3 && campaign.design_rejctions">
          <div class="row">
            <div class="col-xl-4 col-md-6 col-sm-12" v-for="(data, index) in campaign.design_rejctions" :key="index">
              <div class="card">
                <div class="card-body p-2">
                  <div class="text-center">
                    <div class="images-section">
                      <div class="image-item">
                        <a target="_blank" :href="data.sticker_file">
                          <!-- <i class="ri-file-zip-line"></i> -->
                          <img :src="require('@/assets/images/file-zip.png')" alt class="avatar-lg mb-2" />
                        </a>
                        <p>Design files</p>
                      </div>
                      <div class="image-item">
                        <a :href="data.sample_file" target="_blank">
                          <img :src="data.sample_file" alt class="avatar-lg mb-2" />
                        </a>
                        <p>Sample image</p>
                      </div>
                    </div>

                    <div class="media-body">
                      <h7 class="text-truncate">
                        <a href="#" class="text-dark font-weight-bold">Reason : `{{ data.reason }}`<br />
                          Rejected at :
                          {{ moment(data.rejected_at).format("lll") }}
                        </a>
                      </h7>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-tab>
        <!-- ====X===Rejected designs====x===== -->
        <!-- ===========Assign drivers========= -->
        <b-tab title="Assign Driver" class="card" v-if="campaign.status === 3">
          <div class="m-auto" style="max-width: 480px; min-height: 540px">
            <div class="py-5">
              <multiselect :multiple="true" label="name" v-model="drivers" :max="
                campaign.no_of_drivers_required -
                campaign.no_of_drivers_assigned
              " track-by="value" :maxHeight="400" :searchable="true" placeholder="Search driver"
                :loading="driverIsLoading" :options="unassignedVehicle"></multiselect>
            </div>
            <div v-if="drivers.length">
              <div class="card p-3" v-for="driver in drivers" :key="driver.value">
                <div class="p-1">
                  <h6>Vehicle Information</h6>
                  Vehicle No. :
                  <strong>{{ driver.data.plate_category }}</strong>
                  {{ driver.data.plate_number }} <br />
                  Model : {{ driver.data.model && driver.data.model.name }} (
                  {{ driver.data.manufacturing_year }} )
                  <br />
                  Manufacture :
                  {{ driver.data.model && driver.data.model.name }}
                </div>
                <div class="p-1">
                  <h6>Owner Details</h6>
                  Name :
                  <strong>{{
                  (driver.data.owner_id.license &&
                    driver.data.owner_id.license.full_name) ||
                    "Not provided"
                  
                  
                  
                  
                  
                  
                  
                  }}</strong>
                  <br />
                  Phone:
                  <strong> {{ driver.data.owner_id.phone_number }} </strong><br />
                  Email : <strong> {{ driver.data.owner_id.email }}</strong>
                </div>
              </div>
            </div>
            <b-button :disabled="drivers.length === 0" style="width: 148px" class="ml-2" @click="assignDrivers">
              <span v-if="addingDriver">Adding...</span>
              <span class="d-flex" v-else><i class="ri-add-circle-line mr-2"></i> Assign Drivers</span>
            </b-button>
          </div>
        </b-tab>
        <!-- ====X======Assign drivers====X==== -->
        <!-- ==========View assigned drivers============ -->
        <b-tab title="View Assigned Drivers" v-if="campaign.status > 2">
          <span>Spent: {{ amountUsed.toFixed(2) }} Dhs</span>

          <b-progress :value="percentage" :max="100" class="mb-2" animated></b-progress>
          <span style="float: right">Total: {{ amountTotal }} Dhs</span>

          <div class="mt-4">
            <button :class="`btn btn-sm m-1 ${currentFilter == 0 ? 'filter-btn-active' : ''
            }`" @click="filterDriver(0)">
              All (<strong> {{ aggreData.all }}</strong>)
            </button>
            <button :class="`btn btn-sm m-2 ${currentFilter == key ? 'filter-btn-active' : ''
            }`" @click="filterDriver(key)" v-for="(status, key) in jobStaus" :key="status">
              {{ status }} All (<strong> {{ aggreData[key] }}</strong>)
            </button>

            <button v-b-toggle.filter-sidebar class="btn btn">
              <i class="ri-filter-2-fill"></i>
            </button>
            <b-sidebar id="filter-sidebar" right shadow>
              <div class="px-3 py-2">
                <div class="filter-container">
                  <b-form-group label="Model" v-slot="{ ariaDescribedby }">
                    <b-form-checkbox-group v-model="modelSelected" :options="availableModel"
                      :aria-describedby="ariaDescribedby" name="available-modal"></b-form-checkbox-group>
                  </b-form-group>
                </div>
                <div class="filter-container">
                  <b-form-group label="Manufacture" v-slot="{ ariaDescribedby }">
                    <b-form-checkbox-group v-model="manufactureSelected" :options="availableManufacture"
                      :aria-describedby="ariaDescribedby" name="available-manufacture" stacked></b-form-checkbox-group>
                  </b-form-group>
                </div>
                <div class="text-center">
                  <b-button @click="applyFilter" variant="primary" size="sm" style="width: 84px">
                    Filter <i class="ri-filter-3-line"></i>
                  </b-button>
                </div>
              </div>
            </b-sidebar>
          </div>

          <div class="my-2 p-2">
            <div v-if="assignedDriversList.length === 0">
              <h6 class="text-center">Assigned Driver list is empty!</h6>
            </div>

            <div v-if="loadingAssignedDrivers">
              <div class="text-center text-danger my-2">
                <b-spinner class="align-middle"></b-spinner>
              </div>
            </div>
            <div class="row" v-else>
              <div v-for="driver in assignedDriversList" :key="driver._id" class="col-md-4 col-sm-6">
                <div class="card p-4" :style="
                  driver.wrapping_finished_at
                    ? 'background-color:#f2f2f2'
                    : ''
                " style="min-height: 420px">
                  <div class="text-center my-2">
                    <strong style="
                        background-color: #000;
                        color: white;
                        padding: 4px;
                        border-radius: 4px;
                      ">
                      {{ getStatus(driver) }}
                    </strong>
                  </div>

                  <div style="position: absolute; right: 0px; top: -2px">
                    <b-dropdown size="lg" variant="link" toggle-class="text-decoration-none" no-caret right>
                      <template #button-content>
                        <i class="ri-settings-3-line"></i><span class="sr-only">More</span>
                      </template>
                      <b-dropdown-item v-if="driver.wrapping_station" @click="
                        $router.push({
                          name: 'Workstation',
                          query: { id: driver.wrapping_station },
                        })
                      ">View Wrapping station</b-dropdown-item>
                      <b-dropdown-item @click="
                        $router.push({
                          name: 'DriverDetails',
                          params: { id: driver.vehicle.owner_id._id },
                        })
                      ">View Driver</b-dropdown-item>
                      <b-dropdown-item @click="
                        CompletedDriverAd(driver)
                      ">Finished Campaign</b-dropdown-item>
                      <b-dropdown-item @click="
                        $router.push({
                          name: 'VehicleInfo',
                          params: { id: driver.vehicle._id },
                        })
                      ">View Vehicle</b-dropdown-item>
                      <b-dropdown-item v-if="driver.status <= 1 && driver.status != -1">
                        <finish-wrapping @reload="getAllAssignedDrivers" :campaignId="driver._id" />
                      </b-dropdown-item>
                      <b-dropdown-item v-if="driver.status === 1 && !driver.wrapping_station">
                        <assign-workstation @reload="getAllAssignedDrivers" :campaignId="driver._id" />
                      </b-dropdown-item>
                      <b-dropdown-item variant="danger" @click="removeDriverFromCampagin(driver._id)">
                        <span v-if="
                          currentDeletingDriver &&
                          currentDeletingDriver === driver._id
                        ">Removing...</span><span v-else>Remove</span>
                      </b-dropdown-item>
                    </b-dropdown>
                  </div>
                  <img :src="
                    driver.vehicle.vehicel_image ||
                    require('@/assets/images/no-car.png')
                  " alt="vehicle image" srcset="" class="rounded m-2" width="80px" height="auto" />
                  <div style="font-size: 12px; font-weight: 800">
                    <div v-if="driver.wrapping_schedule_date">
                      Wrapping schedule date :
                      {{ moment(driver.wrapping_schedule_date).format("LLL") }}
                    </div>

                    <div v-if="driver.wrapping_started_at">
                      Wrapping Started at:
                      {{ moment(driver.wrapping_started_at).format("LLL") }}
                    </div>
                    <div v-if="driver.wrapping_finished_at">
                      Wrapping finished at :
                      {{ moment(driver.wrapping_finished_at).format("LLL") }}
                    </div>
                    <div>
                      Expires At :
                      <span style="color: red">
                        {{
  timeToRemove(
    driver.wrapping_finished_at,
  campaign.no_of_days
                        ) || "N/A"







                        }}
                      </span>
                    </div>
                  </div>

                  <div class="py-2">
                    <h6>Vehicle Information</h6>
                    Vehicle No. :
                    <strong>{{ driver.vehicle.plate_category }}</strong>
                    {{ driver.vehicle.plate_number }}
                    <div v-if="driver.vehicle.model">
                      Location : {{ driver.vehicle.city || 'Riyadh' }}<br />
                      Model : {{ driver.vehicle.model.name }}<br />
                      Manufacture :
                      {{
                        driver.vehicle.model &&
                          driver.vehicle.model.manufacture.name
                      }}
                    </div>
                  </div>
                  <div>
                    <h6>Driver Details</h6>
                    Email
                    {{
                      driver.vehicle &&
                        driver.vehicle.owner_id &&
                        driver.vehicle.owner_id.email
                    }}
                    <br />
                    Phone Number
                    {{
                      driver.vehicle &&
                        driver.vehicle.owner_id &&
                        driver.vehicle.owner_id.phone_number
                    }}

                    <br/>
                    Last location update at
                       {{
                        driver.location?moment(driver.location.createdAt).format("YYYY-MMM-DD HH-mm"):'not available'
                      }}
                    <br />
                    <div v-if="driver.vehicle &&
                    driver.vehicle.owner_id &&
                    driver.vehicle.owner_id.serial_no">
                      TMS: <div @click="inspectionSelected(driver)">{{ driver.vehicle.owner_id.serial_no }}</div>
                    </div>
                  </div>

                

                </div>
              </div>
            </div>
          </div>
        </b-tab>

      
        <b-tab title="Tickets" v-if="campaign">
          <campaigns-ticket :campaignId="campaign._id" :loadCampaigns="loadCampaigns" />
        </b-tab>
        <!-- ====X=====View assigned drivers=====X====== -->
      </b-tabs>
      <DesignRejectModal :campaignId="campaign._id" v-on:closeModal="hideActionModal" v-on:reloadData="getCampaign" />
    </div>
    <div>
      <b-modal id="updatePriceModal" ref="updatePriceModal" title="Update Price" hide-footer>
        <b-form-group id="input-group-1" label="Enter Price:" label-for="input-1"
          description="The new price of the selected plan">
          <b-form-input type="number" v-model="updatedPrice" placeholder="Please enter the price">
          </b-form-input>
        </b-form-group>
        <div class="mt-2 text-center">
          <b-button @click="updatePlanPrice">Update</b-button>
        </div>
      </b-modal>
    </div>
    <bonus-modal :data="campaign" :value="campaign.bonus_amount" v-on:bonusChanged="bonusChanged" />
  </Layout>
</template>

<script>
import AssignWorkstation from "@/components/driver/AssignWorkstation";
import { jobStaus } from "@/config/data/status";
import Layout from "@/views/layouts/main";
import Multiselect from "vue-multiselect";
import { campaignData } from "@/data/campaign";
import { campaigns } from "@/config/api/campaigns";
import { driver } from "@/config/api/drivers";
import campaignsTicket from "@/components/campaigns/campaignsTicket";
import bonusModal from "./bonusModal.vue";
import Swal from "sweetalert2";
import PaymentModal from "@/components/campaigns/payment-modal";
import DesignRejectModal from "@/components/campaigns/design-reject-modal";
import StartTimeModal from "./startTimeModal.vue";
import flatRateModal from "@/components/campaigns/flat-rate-modal";
// import CampaignSampleImageModal from "@/components/campaigns/campaign-sample-image-size";
import ChangeDays from "@/components/campaigns/change-days";
import ChangeNoDrivers from "@/components/campaigns/driver-required-modal";
import ChangeCompany from "@/components/campaigns/change-company";
import FinishWrapping from "@/components/campaigns/finish-wrapping";

export default {
  components: {
    Layout,
    Multiselect,
    DesignRejectModal,
    campaignsTicket,
    PaymentModal,
    bonusModal,
    StartTimeModal,
    flatRateModal,
    AssignWorkstation,
    ChangeDays,
    ChangeNoDrivers,
    FinishWrapping,
    ChangeCompany
  },
  mounted() { },
  created() {
    this.getCampaign();
    this.getAllAssignedDrivers();
    this.getUnAssignedDrivers();
  },
  data() {
    return {
      adding_new_design: false,
      jobStaus: jobStaus,
      activeTab: 0,
      percentage: 0,
      result: 0,
      amountTotal: 0,
      amountUsed: 0,
      progressWidth: 100,
      campaignData: campaignData,
      campaign: { status: null },
      file: null,
      drivers: [],
      lastLocations:[],
      driverIsLoading: false,
      unassignedVehicle: [],
      loadingAssignedDrivers: false,
      addingDriver: false,
      removingDriver: false,
      currentDeletingDriver: null,
      assignedDriversList: [],
      assignedDriversListData: [],
      submitingForApproval: false,
      updateStatus: false,
      loadingDetails: false,
      loadCampaigns: false,
      paymentActionStatus: false,
      currentFilter: 0,
      updatedPrice: 0,
      showDriverInfo: null,
      isPremium: null,
      campaignImageFile:null,
      modelSelected: [],
      manufactureSelected: [],
      aggreData: {
        all: 0,
        1: 0,
        2: 0,
        3: 0,
        "-1": 0,
      },
      currentFiles: {
        id: 0,
        sample: "",
        design: "",
      },
      designListID: 0,
      designList: [],
      options: ["list", "of", "options"],
    };
  },

  computed: {
    availableManufacture() {
      const data = this.assignedDriversListData.map((item) => {
        return item.vehicle.model.manufacture.name;
      });
      return [...new Set(data)];
    },
    availableModel() {
      const data = this.assignedDriversListData.map((item) => {
        return item.vehicle.model.name;
      });
      return [...new Set(data)];
    },
  },
  methods: {
    openupdatePriceModal(rowData) {
      this.$refs["updatePriceModal"].show();
      this.selectedCampaign = rowData
    },
    closeModal() {
      this.$refs["updatePriceModal"].hide();
      this.getCampaign();
      this.getAllAssignedDrivers();
      this.getUnAssignedDrivers();
    },
    updatePlanPrice() {
      const api = campaigns.updatePlanPrice;
      api.data = {
        campaignId: this.$route.params.id,
        plan_price: this.updatedPrice,
      };

      this.loading = true;

      this.generateAPI(api).then((resdata) => {
        if (resdata.status == 200) {
          this.$bvToast.toast("Updated successfully!", {
            title: "Create",
            variant: "success",
            solid: true,
          });
        }
        this.closeModal();
      });
    },
    changeShowDriverDetailStatus() {
      const api = campaigns.changeShowDriverDetailStatus;
      api.data = {
        campaignId: this.$route.params.id,
        show_vehicle_info: this.showDriverInfo,
      };

      this.loading = true;

      this.generateAPI(api).then((resdata) => {
        if (resdata.status == 200) {
          this.$bvToast.toast("Updated successfully!", {
            title: "Create",
            variant: "success",
            solid: true,
          });
          this.showDriverInfo = resdata.data.campaign.show_vehicle_info
        }
        this.closeModal();
      });
    },
    changePremiumStatus() {
      const api = campaigns.updatePrmium;
      api.data = {
        campaignId: this.$route.params.id,
        is_premium: this.isPremium,
      };

      this.loading = true;

      this.generateAPI(api).then((resdata) => {
        if (resdata.status == 200) {
          this.$bvToast.toast("Updated successfully!", {
            title: "Create",
            variant: "success",
            solid: true,
          });
          this.isPremium = resdata.data.campaign.is_premium
        }
        this.closeModal();
      });
    },
    inspectionSelected(data) {
      this.$router.push({ name: "TrackDriver", query: { driver_id: data.vehicle.owner_id._id } });
    },
    CompletedDriverAd(driver) {

      const api = campaigns.completeDriverAd;
      api.data = {
        "ad_id": driver._id
      }
      this.loadingAssignedDrivers = true;
      this.generateAPI(api)
        .then(() => {

        })
        .catch((err) => {
          console.log(err.data);
        })
        .finally(() => {
          this.getAllAssignedDrivers();
        });
    },
    viewAnalytics(campaignId) {
      this.$router.push({ name: "CampaignMapAnalytics", params: { campaignId } });
    },
    // wrapp
    getStatus(data) {
      if (data.status === 1 && !data.wrapping_station) {
        return "Wrapping station not assigned";
      } else {
        return this.jobStaus[data.status];
      }
    },
    updateTabs() {
      this.increment();
    },
    increment() {
      // console.log(this.percentage * 100)
      // if(this.percentage > this.result + 1)
      // {
      //   this.result += 1
      // }
      // else
      //   this.result += 1 - (this.percentage * 100)
      // if (this.result <= (this.percentage * 100) && this.activeTab === 2) {
      //   setTimeout(this.increment, 60)
      // }
    },
    getAllAssignedDrivers() {
      const api = campaigns.getAssignedDrivers;
      api.id = this.$route.params.id;
      this.loadingAssignedDrivers = true;
      this.generateAPI(api)
        .then((res) => {
          this.assignedDriversListData = res.data.vehicles;
          this.aggreData = {
            all: this.assignedDriversListData.length,
            1: this.assignedDriversListData.filter((item) => item.status == 1)
              .length,
            2: this.assignedDriversListData.filter((item) => item.status == 2)
              .length,
            3: this.assignedDriversListData.filter((item) => item.status == 3)
              .length,
            "-1": this.assignedDriversListData.filter(
              (item) => item.status == "-1"
            ).length,
          };
          this.assignedDriversList = res.data.vehicles;
        })
        .catch((err) => {
          console.log(err.data);
        })
        .finally(() => {
          this.loadingAssignedDrivers = false;
        });
    },
    showActionModal() {
      this.$bvModal.show("reject-design");
    },
    hideActionModal() {
      this.$bvModal.hide("reject-design");
    },
    getCampaign() {
      const api = campaigns.getCampaignDetails;
      this.loadingDetails = true;
      api.id = this.$route.params.id;
      this.generateAPI(api)
        .then((res) => {
          this.campaign = res.data.campaign;
          this.amountTotal = res.data.campaign.plan_price;
          this.amountUsed = res.data.campaign.amount_used;
          this.percentage =
            (res.data.campaign.amount_used * 100) /
            res.data.campaign.plan_price;
          console.log(
            res.data.campaign.amount_used / res.data.campaign.plan_price
          );
          this.designList = res.data.campaign.campaign_stickers;
          this.designListID = res.data.campaign.campaign_stickers.length
          this.showDriverInfo = res.data.campaign.show_vehicle_info
          this.isPremium = res.data.campaign.is_premium
        })
        .finally(() => {
          this.loadingDetails = false;
        });
    },

  
    assignDrivers() {
      const data = {
        campaignId: this.campaign._id,
        vehicleId: [],
      };

      this.drivers.forEach((ele) => {
        data.vehicleId.push(ele.value);
      });
      const api = campaigns.assignDriver;
      api.data = data;
      this.addingDriver = true;
      this.generateAPI(api)
        .then((res) => {
          this.campaign.no_of_drivers_assigned =
            res.data.campaigns.no_of_drivers_assigned;

          this.getUnAssignedDrivers();
          this.getAllAssignedDrivers();
          this.drivers = [];
          this.$bvToast.toast("Drivers added successfully!", {
            title: "Assign Driver",
            variant: "success",
            solid: true,
          });
        })
        .catch(() => {
          this.$bvToast.toast("Drivers are not assigned!", {
            title: "Assign Driver",
            variant: "danger",
            solid: true,
          });
        })
        .finally(() => {
          this.addingDriver = false;
        });
    },
    verifyCampaign() {
      const api = campaigns.verify;
      api.data = {
        campaign_id: this.$route.params.id,
      };
      this.updateStatus = true;
      this.generateAPI(api)
        .then((res) => {
          this.campaign.status = res.data.status;
          this.$bvToast.toast("Campaign verified!", {
            title: "Verify Campaign",
            variant: "success",
            solid: true,
          });
          this.campaign = res.data.message;
        })
        .catch((err) => {
          this.$bvToast.toast("Campaign not verified!", {
            title: "Verify Campaign",
            variant: "danger",
            solid: true,
          });
          console.error(err);
        })
        .finally(() => {
          this.updateStatus = false;
        });
    },
    verifyDesign() {
      console.log("Verify design");
      const api = campaigns.approveDesign;
      api.data = {
        campaign_id: this.$route.params.id,
      };
      this.updateStatus = true;
      this.generateAPI(api)
        .then((res) => {
          this.campaign.status = res.data.campaign.status;
          this.$bvToast.toast("Design verified!", {
            title: "Verify Design",
            variant: "success",
            solid: true,
          });
        })
        .catch((err) => {
          this.$bvToast.toast("Design not verified!", {
            title: "Verify Design",
            variant: "danger",
            solid: true,
          });
          console.error(err);
        })
        .finally(() => {
          this.updateStatus = false;
        });
    },
    addNewDesigns() {
      const api = campaigns.upload_design;
      this.adding_new_design = true;
      if (!this.currentFiles.sample || !this.currentFiles.design) {
        Swal.fire("Error!", "Please upload file first", "error");
        return;
      }
      let sticker_file = this.currentFiles.design;
      let sample_file = this.currentFiles.sample;
      api.data = {
        campaignId: this.$route.params.id,
        sticker_file,
        sample_file
      };
      this.generateAPI(api)
        .then(() => {
          this.designList.push({
            id: this.designListID++,
            sticker_file: this.currentFiles.design,
            sample_file: this.currentFiles.sample,
          });
          this.$bvToast.toast("Design submitted!", {
            title: "Design submit",
            variant: "success",
            solid: true,
          });
        })
        .catch((err) => {
          console.log(err.data);
        })
        .finally(() => {
          this.adding_new_design = false;
        });
    },
    designCompleted() {
      const api = campaigns.verify_design;
      api.data = {
        campaignId: this.$route.params.id,
      };
      this.submitingForApproval = true;
      this.generateAPI(api)
        .then(() => {
          this.$bvToast.toast("Design submitted!", {
            title: "Design submit",
            variant: "success",
            solid: true,
          });
          this.getCampaign();
        })
        .catch((err) => {
          console.log(err.data);
        })
        .finally(() => {
          this.submitingForApproval = false;
        });
    },
    designDone() {
      const api = campaigns.designDoneV2;
      const data = this.designList;
      api.data = {
        campaignId: this.$route.params.id,
        campaign_stickers: data,
      };
      this.submitingForApproval = true;
      this.generateAPI(api)
        .then(() => {
          this.$bvToast.toast("Design submitted!", {
            title: "Design submit",
            variant: "success",
            solid: true,
          });
          this.getCampaign();
        })
        .catch((err) => {
          console.log(err.data);
        })
        .finally(() => {
          this.submitingForApproval = false;
        });
    },
    changeStatus(status) {
      this.$store.dispatch("campaign/changeCampaignState", status);
    },
    goToTrackView() {
      this.$router.push({ name: "TrackDriver", query: { campaign_id: this.$route.params.id } });
    },
    getUnAssignedDrivers() {
      this.driverIsLoading = true;
      const api = driver.getUnAssignedDrivers;
      api.params = {
        campaignId: this.$route.params.id,
      };
      this.generateAPI(api)
        .then((res) => {
          this.unassignedVehicle = res.data.vehicle.map((item) => {
            console.log(item);
            return {
              name:
                item.owner_id &&
                item.owner_id.email + " - " + item.owner_id.phone_number,
              value: item._id,
              data: item,
            };
          });
        })
        .finally(() => {
          this.driverIsLoading = false;
        });
    },

    uploadSampleFile(file, progress) {
      this.uploadFile(file, progress, "sample");
    },
    uploadFile(file, progress, type) {
      const api = campaigns.uploadDesignV2;
      this.fileUploaderV2(file, progress, api, type);
    },
    uploadDesignFile(file, progress) {
      this.uploadFile(file, progress, "design");
    },

    async fileUploader(file, progress, api) {
      const form = new FormData();
      form.append("file", file);
      form.append("campaign_id", this.campaign._id);

      api.data = form;
      const result = await this.fileUploadAPI(api, progress)
        .then((res) => {
          this.$bvToast.toast("Design uploaded successfully!", {
            title: "Upload Design",
            variant: "success",
            solid: true,
          });
          if (res.data.campaign.sticker_file) {
            this.campaign.sticker_file = res.data.campaign.sticker_file;
          }
          if (res.data.campaign.sample_file) {
            this.campaign.sample_file = res.data.campaign.sample_file;
          }

          return res.data;
        })
        .catch(() => {
          this.$bvToast.toast("Design is not uploaded!", {
            title: "Upload Design",
            variant: "danger",
            solid: true,
          });
        });
      return result;
    },
    async fileUploaderV2(file, progress, api, type) {
      const form = new FormData();
      form.append("file", file);
      api.data = form;
      const result = await this.fileUploadAPI(api, progress)
        .then((res) => {
          this.$bvToast.toast("Design file uploaded successfully!", {
            title: "Upload Design",
            variant: "success",
            solid: true,
          });
          this.currentFiles[type] = res.data.location;
          return res.data;
        })
        .catch(() => {
          this.$bvToast.toast("Design file is not uploaded!", {
            title: "Upload Design",
            variant: "danger",
            solid: true,
          });
        });
      return result;
    },

    // =========camoaign image upload========
    async uploadCampaignImage(file, progress) {
      var api = campaigns.uploadCampaignImage;
      const form = new FormData();
      form.append("file", file);
      form.append("campaign_id", this.$route.params.id);
      api.data = form;
       await this.fileUploadAPI(api, progress)
        .then((res) => {
          this.$bvToast.toast("Campaign image uploaded successfully!", {
            title: "Campaign image",
            variant: "success",
            solid: true,
          });
          this.campaign.images = res.data.campaign.images;
          this.campaignImageFile = null;
          return res.data;
        })
         .catch((err) => {
          console.log(err);
          this.$bvToast.toast("Campaign image is not uploaded!", {
            title: "Campaign image",
            variant: "danger",
            solid: true,
          });
        });
      // console.log(result);
      // return result;
    },

    async deleteCampaignImage(index) {
      this.$bvModal
        .msgBoxConfirm(
          "Please confirm that you want to remove image?",
          {
            title: "Remove Image",
            size: "sm",
            buttonSize: "sm",
            okVariant: "danger",
            okTitle: "Confirm",
            cancelTitle: "Cancel",
            footerClass: "p-2",
            hideHeaderClose: false,
            centered: true,
          }
        )
        .then((value) => {

          if (value) {
            var api = campaigns.deleteCampaignImage;
            api.data = { campaign: this.$route.params.id, index };
            this.generateAPI(api).then((res) => {
              this.$bvToast.toast("Campaign image deleted successfully!", {
                title: "Campaign image",
                variant: "success",
                solid: true,
              });
              this.campaign.images = res.data.campaign.images;
              // console.log(res.data);
            }).catch((err) => {
              console.log(err);
            })
          }
        });
      
    },

    // ===========x======x==========
    removeDriverFromCampagin(id) {
      this.$bvModal
        .msgBoxConfirm(
          "Please confirm that you want to remove driver from assigned campaign?",
          {
            title: "Remove Driver",
            size: "sm",
            buttonSize: "sm",
            okVariant: "danger",
            okTitle: "Confirm",
            cancelTitle: "Cancel",
            footerClass: "p-2",
            hideHeaderClose: false,
            centered: true,
          }
        )
        .then((value) => {
          if (value) {
            const api = campaigns.removeAssignedDriver;
            api.data = { campaignAdId: id };
            this.currentDeletingDriver = id;
            this.generateAPI(api)
              .then((res) => {
                this.campaign.no_of_drivers_assigned =
                  res.data.campaign.no_of_drivers_assigned;
                this.assignedDriversList = this.assignedDriversList.filter(
                  (item) => item._id !== id
                );
                this.$bvToast.toast("Driver removed successfully!", {
                  title: "Driver",
                  variant: "success",
                  solid: true,
                });
                this.getUnAssignedDrivers();
              })
              .catch(() => {
                this.$bvToast.toast("Driver is not removed!", {
                  title: "Driver",
                  variant: "danger",
                  solid: true,
                });
              })
              .finally(() => {
                this.currentDeletingDriver = null;
              });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    tabChanged(val) {
      console.log("TAB IS CHANGING...");
      console.log(val);
    },
    paymentAction() {
      this.$bvModal.show("campaign-payment-action");
    },
    loadAndHide() {
      this.campaign = null;
      this.hideActionModal();
      this.getCampaign();
    },
    bonusChanged(value) {
      this.campaign.bonus_amount = value;
    },
    sampleImageChange(url) {
      this.campaign.sample_file = url;
    },
    changeFlatRate(val) {
      this.campaign.flat_rate = val;
    },
    deleteCampaign(id) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.value) {
          this.deleteAction(id);
        }
      });
    },
    deleteAction(id) {
      const api = campaigns.deleteCampaign;
      api.id = id;
      this.generateAPI(api)
        .then(() => {
          if (this.campaignData.length === 1) {
            const page =
              this.currentPage == 1
                ? this.currentPage
                : parseInt(this.currentPage) - 1;

            this.$store.dispatch("campaign/changePage", page);
            this.getAllCampigns(page);
            this.currentPage = page;
          } else {
            this.getAllCampigns(this.currentPage);
          }

          Swal.fire("Deleted!", "Campaign has been deleted.", "success");
        })
        .catch((err) => {
          Swal.fire(
            "Error!",
            `Campaign is not deleted,${err.response.data.error}`,
            "error"
          );
        });
    },

    //finish camapaign method
    finishCampaign(id) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "Yes, finish it!",
      }).then((result) => {
        if (result.value) {
          this.finishAction(id);
        }
      });
    },
    finishAction(id) {
      const api = campaigns.finishCampaign
      api.data = { campaign_id: id };
      this.generateAPI(api)
        .then(() => {
          if (this.campaignData.length === 1) {
            const page =
              this.currentPage == 1
                ? this.currentPage
                : parseInt(this.currentPage) - 1;

            this.$store.dispatch("campaign/changePage", page);
            this.getAllCampigns(page);
            this.currentPage = page;
          } else {
            this.getAllCampigns(this.currentPage);
          }

          Swal.fire("Finished!", "Campaign has been finished.", "success");
        })
        .catch((err) => {
          Swal.fire(
            "Error!",
            `Campaign is not finished,${err.response.data.error}`,
            "error"
          );
        });
    },

    dayChange(no_of_days) {
      this.campaign.no_of_days = no_of_days;
    },
    changeDriverRequired(no_of_drivers_required) {
      this.campaign.no_of_drivers_required = no_of_drivers_required;
    },
    changeCompany(company) {
      this.campaign.company = company;
    },
    filterDriver(status) {
      this.manufactureSelected = [];
      this.modelSelected = [];
      this.currentFilter = status;
      status != 0
        ? (this.assignedDriversList = this.assignedDriversListData.filter(
          (item) => item.status == status
        ))
        : (this.assignedDriversList = [...this.assignedDriversListData]);
    },
    timeToRemove(started_date, days) {
      if (started_date) {
        const endDate = this.moment(started_date).add(days, "days");

        return endDate.format("LLL");
      } else {
        return 0;
      }
    },
    applyFilter() {
      this.currentFilter != 0
        ? (this.assignedDriversList = this.assignedDriversListData.filter(
          (item) => item.status == this.currentFilter
        ))
        : (this.assignedDriversList = [...this.assignedDriversListData]);
      if (this.manufactureSelected.length && this.modelSelected.length) {
        this.assignedDriversList = this.assignedDriversList.filter((item) => {
          if (
            this.manufactureSelected.includes(
              item.vehicle.model.manufacture.name
            ) ||
            this.modelSelected.includes(item.vehicle.model.name)
          ) {
            return item;
          }
        });
      } else if (this.manufactureSelected.length) {
        this.assignedDriversList = this.assignedDriversList.filter((item) => {
          if (
            this.manufactureSelected.includes(
              item.vehicle.model.manufacture.name
            )
          ) {
            return item;
          }
        });
      } else if (this.modelSelected.length) {
        this.assignedDriversList = this.assignedDriversList.filter((item) => {
          if (this.modelSelected.includes(item.vehicle.model.name)) {
            return item;
          }
        });
      }
    },
    makeRecurring(id) {
      this.$bvModal
        .msgBoxConfirm(
          "Please confirm that you want to toggle recurrence status?",
          {
            title: "Change recurrence status",
            size: "sm",
            buttonSize: "sm",
            okVariant: "danger",
            okTitle: "Confirm",
            cancelTitle: "Cancel",
            footerClass: "p-2",
            hideHeaderClose: false,
            centered: true,
          }
        )
        .then((value) => {
          if (value) {
            const api = campaigns.changeRecurrence;
            api.data = {
              campaign_id: id,
              recurring: !this.campaign.recurring,
            };
            this.generateAPI(api)
              .then(() => {
                Swal.fire("Changed recurrence status!", "", "success");
                this.campaign.recurring = !this.campaign.recurring;
              })
              .catch((err) => {
                Swal.fire(
                  "Error!",
                  `Recurrence status is not changed!,${err.response.data.error}`,
                  "error"
                );
              });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    expired(campaign) {
      if (campaign.recurring) {
        return (this.moment(campaign.start_within).add(
          campaign.no_of_days,
          "days"
        ) > this.moment());
      } else {
        return this.moment(campaign.start_within) < this.moment();
      }
    },
    addDesign() {
      if (this.currentFiles.sample && this.currentFiles.design) {
        this.designList.push({
          id: this.designListID,
          sticker_file: this.currentFiles.design,
          sample_file: this.currentFiles.sample,
        });
        this.designListID = this.designListID + 1;
      } else {
        Swal.fire("Error!", "Please upload file first", "error");
      }
    },
    removeFile(type) {
      this.currentFiles[type] = "";
    },
    deleteItem(sample_file) {

      const api = campaigns.remove_design;
      api.data = {
        campaignId: this.$route.params.id,
        sample_file,
      };
      this.generateAPI(api)
        .then(() => {
          this.$bvToast.toast("Design submitted!", {
            title: "Design submit",
            variant: "success",
            solid: true,
          });
          this.designList = this.designList.filter((item) => item.sample_file != sample_file);
        })
        .catch((err) => {
          console.log(err.data);
        })
        .finally(() => {

        });
      console.log(sample_file);
    },
  },
  watch: {
    activeTab(val) {
      if (val === 1) {
        this.loadCampaigns = true;
      } else {
        this.loadCampaigns = false;
      }
    },
  },
};
</script>

<style>
.verify-btn {
  border: 2px solid #868686ec;
  font-weight: 900;
}

.verify-btn:hover {
  background: #868686ec;
}

.trade-licence {
  background-color: #fafafa;
  padding: 2px 8px;
  border-radius: 8px;
}

.trade-licence:hover {
  background-color: #f1f1f1;
}

.campaign-details .title {
  text-align: center;
  padding-top: 18px;
  font-weight: 900;
  color: black;
  text-transform: uppercase;
}

.campaign-details .col-4 {
  font-weight: 900;
}

.plan-details .row {
  margin-bottom: 4px;
}

.campaign-details .status-title {
  font-size: 16px;
  font-weight: 900;
  text-align: center;
  margin-bottom: 12px;
}

.download-file {
  box-shadow: -1px 1px 8px 0px rgb(139 120 120 / 27%);
  -webkit-box-shadow: -1px 1px 8px 0px rgb(139 120 120 / 27%);
  -moz-box-shadow: -1px 1px 8px 0px rgb(139 120 120 / 27%);
  padding: 28px 28px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 900;
  display: block;
  text-align: center;
}

.download-file:hover {
  color: gray;
}

.download-btn {
  background-color: purple;
  color: #fff;
  margin: 5px 10px;
  padding: 5px 10px;
  display: block;
  border-radius: 7px;
}

.download-btn:hover {
  color: #fff;
}

.images-section {
  display: flex;
  justify-content: space-evenly;
}

.progress {
  height: 25px;
  background: rgba(255, 255, 255);
  /* background: #ccc;
  border-radius: 100px;
  position: relative;
  padding: 5px 5px;
  margin: 5px 5px;
  width: auto; */
}

.progress-value {
  height: 18px;
  background: rgba(0, 0, 0, 0.123);

  /* transition: width 0.2s linear ; */
  /* transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 1s;
  */
  /* border-radius: 100px;
 width: auto;
  background: #fff;
  text-align: center; */
}

.bonus-btn {
  background-color: black;
  color: white;
  border-radius: 4px;
  font-size: 11px;
}

.filter-btn-active {
  outline: 0;
  box-shadow: 0 0 0 0.15rem rgba(5, 5, 5, 0.25);
}

.remove-msg {
  background-color: red;
  color: white;
  padding: 4px;
  border-radius: 4px;
  font-weight: 600;
}

.filter-container {
  background-color: #ffffff;
  padding: 8px;
  margin: 8px;
}

.expired-block {
  text-align: center;
  font-size: 16px;
  margin-bottom: 8px;
}

.expired-block span {
  background: rgb(226, 59, 59);
  color: #fff;
  padding: 4px 12px;
  border-radius: 4px;
}
</style>
